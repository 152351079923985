<template>
  <a-row>
    <a-col :xxl="10" :lg="12" :md="8" :xs="24">
      <Aside>
        <div class="auth-side-content">
          <Content>
          </Content>
        </div>
      </Aside>
    </a-col>

    <a-col :xxl="12" :xl="12" :lg="12" :md="16" :xs="24" style="height: 100vh; display: flex; flex-direction: column;">
      <div class="text-right p-3">
        <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent>
            <a href="javascript:;">
              <img :src="activeLang.url" alt="Idioma" class="activeLang" width="30"/>
              <span style="font-size: 13px; margin-left: 4px;">{{ activeLang.alt }}</span>
            </a>
          </a>
          <template #overlay>
            <div>
              <a
                v-for="lan in languages"
                :key="lan.locale"
                @click="() => onFlagChangeHandle(lan)"
                to="#"
              >
                <img :src="lan.url" alt="lan.alt" width="30" />
                <span>{{ lan.alt }}</span>
              </a>
            </div>
          </template>
        </a-dropdown>
      </div>
      <router-view></router-view>
    </a-col>
  </a-row>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { Aside, Content } from './style';
import spainIcon from "@/components/icons/spain.png";
import gbIcon from "@/components/icons/great-britain.png";
import { ref } from 'vue';

const { locale } = useI18n();

const languages = [
  {
    url: spainIcon,
    alt: "Español",
    locale: "es",
    numberFormats: "es-ES",
    datetimeFormats: "es-ES",
  },
  {
    url: gbIcon,
    alt: "English",
    locale: "en",
    numberFormats: "en-EN",
    datetimeFormats: "en-EN",
  },
];

const activeLang = ref(languages[0]);

const onFlagChangeHandle = (e) => {
  locale.value = e.locale;
  activeLang.value = e;
};
</script>
<style scoped>
.flag-item {
  padding: 2px 6px;
}
</style>